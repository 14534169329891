// ** Third Party Components
import loadable from "@loadable/component";

// ** Containers
// import Wrapper from "containers/Wrapper/Wrapper";
// import Main from "containers/Main/Main";
const Wrapper = loadable(() => import("containers/Wrapper/Wrapper"));
const Main = loadable(() => import("containers/Main/Main"));
import containers from "containers";

// ** Utils & Helpers
import pathPatterns from "utils/pathPatterns";

// ** HOME **
const Home = loadable(() => import("containers/Home/Home"), { ssr: false });

// ** NEARBY **
const Nearby = loadable(() => import("containers/Nearby/Nearby"), {
	ssr: false,
});

// ** PAYMENTS
const Payments = loadable(() => import("containers/Payments/Payments"), {
	ssr: false,
});

// ** REGISTER VERIFY EMAIL **
const RegisterVerifyRedirect = loadable(
	() =>
		import(
			"containers/Landing/containers/RegisterVerify/RegisterVerifyRedirect"
		),
	{
		ssr: false,
	}
);

// ** REGISTER VERIFY PHONE **
const RegisterVerifyPhone = loadable(
	() =>
		import(
			"containers/Landing/containers/RegisterVerify/RegisterVerifyPhone"
		),
	{
		ssr: false,
	}
);

// ** REGISTER VERIFY PHONE **
const RegisterVerifySubmitCode = loadable(
	() =>
		import(
			"containers/Landing/containers/RegisterVerify/RegisterVerifySubmitCode"
		),
	{
		ssr: false,
	}
);

// ** NEWS **
const News = loadable(() => import("containers/News/News"));
const List = loadable(() => import("containers/News/containers/List/List"));
const Article = loadable(() =>
	import("containers/News/containers/Article/Article")
);

// ** APP **
const App = loadable(() => import("containers/App/App"));

// ** CONTACT **
const Contact = loadable(() => import("containers/Contact/Contact"));

// ** USERPANEL **
const Userpanel = loadable(() => import("containers/Userpanel/Userpanel"), {
	ssr: false,
});

// ** USER PAGE **
const User = loadable(() => import("containers/User/User"));
const UserFriends = loadable(
	() => import("containers/User/containers/Friends/Friends"),
	{ ssr: false }
);
const UserFriendsCurrent = loadable(
	() => import("containers/User/containers/Friends/containers/FriendsList"),
	{ ssr: false }
);
const UserFriendsFollowers = loadable(
	() => import("containers/User/containers/Friends/containers/Followers"),
	{ ssr: false }
);
const UserFriendsFollowing = loadable(
	() => import("containers/User/containers/Friends/containers/Following"),
	{ ssr: false }
);
const UserGallery = loadable(
	() => import("containers/User/containers/Gallery/Gallery"),
	{ ssr: false }
);

const UserProfile = loadable(() =>
	import("containers/User/containers/Profile/Profile")
);

// // ** USERS SEARCH LIST **
const Users = loadable(() => import("containers/Users/Users"));

const PreferencesListing = loadable(() =>
	import("containers/PreferencesListing/PreferencesListing")
);
const LocationSeo = loadable(() =>
	import("containers/LocationSeo/LocationSeo")
);
import { initialQuery as usersInitalQuery } from "containers/Users/initialQuery";

// ** RANKINGS **
const Rankings = loadable(() => import("containers/Rankings/Rankings"));

// ** GALLERY **
const Gallery = loadable(() => import("containers/Gallery/Gallery"));

// **  TERMS **
const Terms = loadable(() => import("containers/Terms/Terms"));

// **  PRIVACY **
const Privacy = loadable(() => import("containers/Privacy/Privacy"));

// **  REPORT **
const Report = loadable(() => import("containers/Report/Report"), {
	ssr: false,
});

// **  SUPPORT **
const Support = loadable(() => import("containers/Support/Support"));

// ** POSTS **
const Posts = loadable(() => import("containers/Posts/Posts"));

// ** ACTIVITY **
const Activity = loadable(() => import("containers/Activity/Activity"), {
	ssr: false,
});

// ** NOT FOUND **
const NotFound = loadable(() => import("containers/NotFound/NotFound"));

const routes = [
	// ** HOME **
	{
		key: "home",
		component: Home,
		path: "/",
		exact: true,
	},
	// ** REGISTER VERIFY EMAIL **
	{
		key: "registerVerify",
		component: RegisterVerifyRedirect,
		path: "/registration/:login?/:id?/:hash?",
	},
	// ** REGISTER VERIFY PHONE **
	{
		key: "registerVerifyPhone",
		component: RegisterVerifyPhone,
		path: "/registration-phone/:login?/:id?/:hash?",
	},
	// ** REGISTER VERIFY SUBMIT CODE **
	{
		key: "registerVerifySubmitCode",
		component: RegisterVerifySubmitCode,
		path: "/registration-code/:login?/:id?/:hash?",
	},
	// ** Payments **
	{
		key: "payments",
		component: Payments,
		path: "/payments",
		exact: true,
		meta: {
			link: "/payments",
		},
	},
	// ** NEARBY **
	{
		key: "nearby",
		component: Nearby,
		path: "/nearby",
		meta: {
			link: "/nearby",
		},
	},
	// ** USERPANEL **
	{
		key: "userpanel",
		component: Userpanel,
		path: "/userpanel",
		meta: {
			suspended: true,
		},
	},
	// ** NEWS **
	{
		key: "news",
		path: "/news",
		component: News,
		routes: [
			{
				key: "newsList",
				component: List,
				path: "/news/:page([0-9]+)?",
				exact: true,
			},
			{
				key: "newsArticle",
				component: Article,
				path: "/news/:id([0-9]+)/:slug([a-z0-9-]+)",
				exact: true,
			},
			{
				key: "notfound",
				component: NotFound,
				path: "*",
			},
		],
		meta: {
			link: "/news",
			suspended: true,
		},
	},
	// ** APP **
	{
		key: "app",
		component: App,
		path: "/app",
		exact: true,
		meta: {
			link: "/app",
			suspended: true,
		},
	},
	// ** CONTACT **
	{
		key: "contact",
		path: `/contact`,
		exact: true,
		component: Contact,
		meta: {
			link: "/contact",
			suspended: true,
		},
	},

	// ** USER PAGE **
	{
		key: "user",
		component: User,
		path: "/users/:login",
		routes: [
			// ** USER -> PROFILE **
			{
				key: "userProfile",
				component: UserProfile,
				path: "/users/:login",
				exact: true,
			},
			// ** USER -> GALLERIES **
			{
				key: "userGallery",
				component: UserGallery,
				path: "/users/:login/gallery",
			},
			// ** USER -> FRIENDS **
			{
				key: "userFriends",
				component: UserFriends,
				path: "/users/:login/friends",
				routes: [
					{
						key: "userFriendsCurrent",
						component: UserFriendsCurrent,
						path: `/users/:login/friends/:sex(${pathPatterns.sexSingle})?/:page(${pathPatterns.page})?`,
						meta: {
							counterKey: "userFriends",
						},
						exact: true,
					},
					{
						key: "userFollowersCurrent",
						component: UserFriendsFollowers,
						path: `/users/:login/friends/followers/:sex(${pathPatterns.sexSingle})?/:page(${pathPatterns.page})?`,
						meta: {
							counterKey: "userFollowers",
						},
						exact: true,
					},
					{
						key: "userFollowingCurrent",
						component: UserFriendsFollowing,
						path: `/users/:login/friends/following/:sex(${pathPatterns.sexSingle})?/:page(${pathPatterns.page})?`,
						meta: {
							counterKey: "userFollowing",
						},
						exact: true,
					},
				],
			},
			{
				key: "notfound",
				component: NotFound,
				path: "*",
			},
		],
	},
	{
		key: "preferencesListing",
		component: PreferencesListing,
		path: "/search/preferences",
		exact: true,
	},
	{
		key: "LocationSeo",
		component: LocationSeo,
		path: "/search/locations",
		exact: true,
	},
	{
		key: "usersPreferences",
		component: Users,
		path: `/search/preferences/:preference`,
		link: "/search/preferences",
		exact: true,
		meta: {
			searchable: true,
			initialQuery: usersInitalQuery,
		},
	},
	{
		key: "usersListing",
		component: Users,
		path: `/search/:country?/:region?/:city?`,
		link: "/search",
		exact: true,
		meta: {
			searchable: true,
			initialQuery: usersInitalQuery,
		},
	},
	// ** RANKINGS **
	{
		...containers.rankings,
		component: Rankings,
		exact: true,
	},
	// ** GALLERY **
	{
		...containers.gallery,
		component: Gallery,
	},

	// **  TERMS **
	{
		key: "terms",
		component: Terms,
		path: "/terms",
		exact: true,
		meta: {
			link: "/terms",
			suspended: true,
		},
	},

	// **  PRIVACY **
	{
		key: "privacy",
		component: Privacy,
		path: "/privacy",
		exact: true,
		meta: {
			link: "/privacy",
			suspended: true,
		},
	},
	// **  REPORT **
	{
		key: "report",
		component: Report,
		path: "/report/:idUser",
		exact: true,
	},
	// **  SUPPORT **
	{
		key: "support",
		component: Support,
		path: "/support",
		exact: true,
		meta: {
			link: "/support",
			suspended: true,
		},
	},
	// ** POSTS **
	{
		...containers.posts,
		component: Posts,
	},
	// ** ACTIVITY **
	{
		...containers.activity,
		component: Activity,
	},

	// ** REFRESH **
	{
		key: "refresh",
		component: () => null,
		path: "/reflesh",
	},
	// ** NOT FOUND **
	{
		key: "notfound",
		component: NotFound,
		path: "*",
	},
];

export default [
	{
		key: "wrapper",
		component: Wrapper,
		path: "/",
		routes: [
			{
				key: "main",
				component: Main,
				path: "/",
				routes,
			},
		],
	},
];
