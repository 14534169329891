/* eslint-disable no-case-declarations */
import defaults from "../defaults";

const GET_COMMENTS = "modal/GET_COMMENTS";
const GET_COMMENTS_SUCCESS = "modal/GET_COMMENTS_SUCCESS";
const GET_COMMENTS_FAIL = "modal/GET_COMMENTS_FAIL";

const ADD_COMMENT = "modal/ADD_COMMENT";
const ADD_COMMENT_SUCCESS = "modal/ADD_COMMENT_SUCCESS";
const ADD_COMMENT_FAIL = "modal/ADD_COMMENT_FAIL";

const REMOVE_COMMENT_SUCCESS = "modal/REMOVE_COMMENT_SUCCES";

const RESET_MODAL = "modal/RESET_MODAL";

const GET_USER_INFO_AND_MEDIA_BULK = "modal/GET_USER_INFO_AND_MEDIA_BULK";
const GET_USER_INFO_AND_MEDIA_BULK_SUCCESS =
	"modal/GET_USER_INFO_AND_MEDIA_BULK_SUCCESS";
const GET_USER_INFO_AND_MEDIA_BULK_FAIL =
	"modal/GET_USER_INFO_AND_MEDIA_BULK_FAIL";

const GET_MEDIA_INFO_AND_COMMENTS_BULK =
	"modal/GET_MEDIA_INFO_AND_COMMENTS_BULK";
const GET_MEDIA_INFO_AND_COMMENTS_BULK_SUCCESS =
	"modal/GET_MEDIA_INFO_AND_COMMENTS_BULK_SUCCESS";
const GET_MEDIA_INFO_AND_COMMENTS_BULK_FAIL =
	"modal/GET_MEDIA_INFO_AND_COMMENTS_BULK_FAIL";

const SET_MAIN_MEDIA_INFO = "modal/SET_MAIN_MEDIA_INFO";

const initialState = defaults.modal;

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case GET_COMMENTS:
			if (action.more) {
				return {
					...state,
					comments: {
						...state.comments,
						loading: true,
					},
				};
			}
			return {
				...state,
				comments: {
					...initialState.comments,
					loading: true,
				},
			};
		case GET_COMMENTS_SUCCESS: {
			const newIds = (action.result.data?.comments || []).map(
				({ id }) => id
			);
			const comments = [
				...state.comments.comments.filter(({ id }) => {
					if (newIds.includes(id)) return false;
					return true;
				}),
				...(action.result.data?.comments || []),
			];

			if (action.result.data) {
				return {
					...state,
					comments: {
						...state.photoComments,
						loading: false,
						success: true,
						totalPages: action.result.data.pagination.totalpages,
						currentPage: action.result.data.pagination.currentpage,
						comments,
						loadedComments: comments.length,
						totalComments: action.result.data.total,
					},
				};
			}
			return {
				...state,
				comments: {
					...state.comments,
					loading: false,
					success: true,
				},
			};
		}
		case GET_COMMENTS_FAIL:
			return {
				...state,
				comments: {
					...state.comments,
					loading: false,
					error: true,
				},
			};
		case ADD_COMMENT:
			return {
				...state,
				comments: {
					...state.comments,
					whileAdding: true,
				},
			};
		case ADD_COMMENT_SUCCESS:
			let comments = [];
			if (
				action.result.data.comments &&
				action.result.data.comments.length > 0
			) {
				comments = [
					action.result.data.comments[0],
					...state.comments.comments,
				];
			} else {
				comments = state.comments.comments;
			}

			return {
				...state,
				comments: {
					...state.comments,
					loading: false,
					whileAdding: false,
					comments,
				},
			};
		case ADD_COMMENT_FAIL:
			return {
				...state,
				comments: {
					...state.comments,
					loading: false,
					whileAdding: false,
					error: true,
				},
			};

		case REMOVE_COMMENT_SUCCESS:
			return {
				...state,
				comments: {
					...state.comments,
					loading: false,
					whileAdding: false,
					comments: action.commentsList || [],
				},
			};
		case RESET_MODAL:
			return {
				...initialState,
				mainMediaInfo: {
					...state.mainMediaInfo,
				},
			};

		// media
		case GET_USER_INFO_AND_MEDIA_BULK:
			return {
				...state,
				userInfo: {
					...state.userInfo,
					loading: true,
					error: false,
				},
				list: {
					...state.list,
					loading: true,
				},
				comments: {
					...state.comments,
					loading: true,
				},
			};
		case GET_USER_INFO_AND_MEDIA_BULK_SUCCESS:
			if (action.result.data?.media.status !== 200) {
				return {
					...state,
					list: {
						...state.list,
						loading: false,
						success: false,
					},
					userInfo: {
						...state.userInfo,
						loading: false,
						data: {},
						error: true,
						errorText: action.result.data?.media?.error,
					},
				};
			}

			const finalTotal =
				state.list.meta.total !== -1
					? state.list.meta.total
					: (action.result.data?.media.response.data.center ? 1 : 0) +
					  (action.result.data?.media.response.data?.right?.length ||
							0) +
					  (action.result.data?.media.response.data?.left?.length ||
							0) +
					  (action.result.data?.media.response.meta?.right_amount ||
							0) +
					  (action.result.data?.media.response.meta?.left_amount ||
							0);

			const preparedList =
				state.list.data.length > 0
					? state.list.data
					: new Array(finalTotal).fill(null).map((x, index) => ({
							_innerIndex: index,
							_filled: false,
					  }));

			// combined
			const newLeftItems =
				action.result.data?.media.response.data?.left?.length || 0;
			const newRightItems =
				action.result.data?.media.response.data?.right?.length || 0;

			// fill current
			const centerExists = preparedList.find(
				(item) => item && item.index === 0
			);
			if (
				action.result.data?.media.response.data.center &&
				!centerExists
			) {
				const leftOffsetForCenter =
					(action.result.data.media.response.meta?.left_amount || 0) +
					newLeftItems;
				const currentArrayIndex = leftOffsetForCenter;
				const item = action.result.data?.media.response.data.center;
				if (preparedList[currentArrayIndex]._filled === false) {
					preparedList[currentArrayIndex] = {
						...preparedList[currentArrayIndex],
						_filled: true,
						index: 0,
						...item,
					};
				}
			}

			// fill left
			if (newLeftItems > 0) {
				const leftItems =
					action.result.data?.media.response.data?.left.reverse();
				const startOffsetForLeft =
					action.result.data?.media.response.meta?.left_amount || 0;
				for (let index = newLeftItems - 1; index >= 0; index--) {
					const currentArrayIndex = startOffsetForLeft + index;
					const item = leftItems[index];
					const currentInPreparedList =
						preparedList[currentArrayIndex];

					if (
						currentInPreparedList &&
						currentInPreparedList._filled === false
					) {
						const newIndex =
							Math.min(
								...preparedList
									.map((item) => item.index || 0)
									.filter((item) => item !== undefined)
							) - 1;
						preparedList[currentArrayIndex] = {
							...preparedList[currentArrayIndex],
							_filled: true,
							index: newIndex,
							...item,
						};
					}
				}
			}

			// fill right
			const startOffsetForRight =
				[...preparedList].findIndex(
					(item) => item && item._filled === true
				) +
				preparedList.filter((item) => item && item._filled === true)
					.length;
			if (newRightItems > 0) {
				const rightItems =
					action.result.data?.media.response.data.right;

				for (let index = 0; index < newRightItems; index++) {
					const currentArrayIndex = startOffsetForRight + index;
					const item = rightItems[index];

					const currentInPreparedList =
						preparedList[currentArrayIndex];
					if (
						currentInPreparedList &&
						currentInPreparedList._filled === false
					) {
						const newIndex =
							Math.max(
								...preparedList
									.map((item) => item.index || 0)
									.filter((item) => item !== undefined)
							) + 1;
						preparedList[currentArrayIndex] = {
							...preparedList[currentArrayIndex],
							_filled: true,
							index: newIndex,
							...item,
						};
					}
				}
			}

			const meta = {
				total: finalTotal,
				left_amount: [...preparedList].findIndex(
					(item) => item._filled === true
				),
				left_hash:
					action.result.data?.media.response.meta.left_hash ||
					state.list.meta.left_hash,
				right_amount: [...preparedList]
					.reverse()
					.findIndex((item) => item._filled === true),
				right_hash:
					action.result.data?.media.response.meta.right_hash ||
					state.list.meta.right_hash,
			};

			return {
				...state,
				userInfo: {
					...state.userInfo,
					loading: false,
					success: true,
					error: false,
					data: {
						userId: action.result.data?.userInfo.response.id,
						avatar: action.result.data?.userInfo.response.avatar,
						login: action.result.data?.userInfo.response.login,
						statusVip:
							action.result.data?.userInfo.response.status_vip,
						online: action.result.data?.userInfo.response.online,
						profileStatus:
							action.result.data?.userInfo.response
								.profile_status,
						privateData:
							action.result.data?.userInfo.response.private_data,
					},
				},
				list: {
					...state.list,
					loading: false,
					success: true,
					data: preparedList,
					meta: {
						...state.list.meta,
						...meta,
					},
				},
			};
		case GET_USER_INFO_AND_MEDIA_BULK_FAIL:
			return {
				...state,
				list: {
					...state.list,
					loading: false,
					success: false,
				},
				userInfo: {
					...state.userInfo,
					loading: false,
					data: {},
					error: true,
				},
			};

		case GET_MEDIA_INFO_AND_COMMENTS_BULK:
			return {
				...state,
				contentInfo: {
					...state.contentInfo,
					loading: true,
				},
				comments: action.onlyContentInfo
					? initialState.comments
					: {
							...state.comments,
							loading: true,
					  },
			};
		case GET_MEDIA_INFO_AND_COMMENTS_BULK_SUCCESS:
			return {
				...state,
				contentInfo: {
					...state.contentInfo,
					loading: false,
					success: true,
					data: {
						contentId: action.result.data?.contentInfo.response?.id,
						userId: action.result.data?.contentInfo.response
							?.user_id,
						albumId:
							action.result.data?.contentInfo.response?.album_new,
						albumName:
							action.result.data?.contentInfo.response
								?.album_name,
						albumCount:
							action.result.data?.contentInfo.response
								?.media_in_album || 0,
						private:
							action.result.data?.contentInfo.response?.private,
						videoUrl:
							action.result.data?.contentInfo.response?.video,
						description: action.result.data?.contentInfo.response
							?.accepted_description
							? action.result.data?.contentInfo.response
									?.description
							: null,
						descriptionRaw: action.result.data?.contentInfo.response
							?.accepted_description
							? action.result.data?.contentInfo.response
									?.description_raw
							: null,
						dateAdd:
							action.result.data?.contentInfo.response?.date_add,
						countComment:
							action.result.data?.contentInfo.response
								?.count_comment,
						countLike:
							action.result.data?.contentInfo.response
								?.count_like,
						width: action.result.data?.contentInfo.response?.width,
						height: action.result.data?.contentInfo.response
							?.height,
						filterLike:
							action.result.data?.contentInfo.response
								?.filter_like,
						filterComment:
							action.result.data?.contentInfo?.response
								?.filter_comment,
						canLike:
							action.result.data?.contentInfo.response?.can_like,
						canComment:
							action.result.data?.contentInfo.response
								?.can_comment,
						limit: action.result.data?.contentInfo.response?.limit,
						iLiked: action.result.data?.contentInfo.response
							?.i_liked,
						iComment:
							action.result.data?.contentInfo.response?.i_comment,
					},
				},
				comments: action.onlyContentInfo
					? initialState.comments
					: {
							...state.photoComments,
							loading: false,
							success: true,
							totalPages:
								action.result.data?.comment.response?.pagination
									?.totalpages,
							currentPage:
								action.result.data?.comment.response?.pagination
									?.currentpage,
							comments:
								action.result.data?.comment.response
									?.comments || [],
							loadedComments:
								action.result.data?.comment.response?.comments
									?.length || 0,
							totalComments:
								action.result.data?.comment.response?.total ||
								0,
					  },
			};
		case GET_MEDIA_INFO_AND_COMMENTS_BULK_FAIL:
			return {
				...state,
				contentInfo: {
					...state.contentInfo,
					loading: false,
					error: true,
				},
				comments: action.onlyContentInfo
					? initialState.comments
					: {
							...initialState.comments,
							loading: false,
							error: true,
					  },
			};
		case SET_MAIN_MEDIA_INFO:
			return {
				...state,
				mainMediaInfo: {
					iLiked: false,
					likesCount: action.payload.likesCount,
					iCommented: false,
					commentsCount: null,
					contentId: action.payload.contentId,
				},
			};
		default:
			return state;
	}
}

/**
 * Pobiera komentarze zdjecia / filmu
 * @api GET getPhotoComments / getVideoComments
 * @param {Number} userId identyfikator uzytkownika
 * @param {Number} id identyfikator zdjęcia
 * @param {Number} type photo / video
 */

export function getComments({
	userId,
	elementId,
	page,
	type = "photo",
	limit,
	more,
}) {
	return {
		types: [GET_COMMENTS, GET_COMMENTS_SUCCESS, GET_COMMENTS_FAIL],
		promise: (client) =>
			client.get(`/comment/file`, {
				params: {
					user: userId,
					id: elementId,
					type,
					page,
					limit,
				},
			}),
		more,
	};
}

/**
 * Dodaje komentarz do zdjecia,video
 * @api POST addPhotoComment / addVideoComment
 * @param {Number} userId identyfikator uzytkownika
 * @param {Number} photoId identyfikator zdjęcia
 * @param {String} comment treść komentarza
 * @param {String} type photo / video
 */

export function addComment(userId, photoId, comment, type = "photo") {
	return {
		types: [ADD_COMMENT, ADD_COMMENT_SUCCESS, ADD_COMMENT_FAIL],
		promise: (client) =>
			client.post("/comment", {
				user: parseInt(userId, 10),
				file: parseInt(photoId, 10),
				type,
				comment,
			}),
	};
}
export function removeComment(commentsList) {
	return {
		type: REMOVE_COMMENT_SUCCESS,
		commentsList,
	};
}

/**
 * Resetuje modal po zamknięciu
 */

export function modalReset() {
	return {
		type: RESET_MODAL,
	};
}

export function mergeBulkUserInfoAndMedia({
	userLogin,
	elementId,
	type,
	vector,
	album,
	rightHash,
	leftHash,
	limit,
}) {
	return {
		types: [
			GET_USER_INFO_AND_MEDIA_BULK,
			GET_USER_INFO_AND_MEDIA_BULK_SUCCESS,
			GET_USER_INFO_AND_MEDIA_BULK_FAIL,
		],
		promise: (client) =>
			client.post(
				"/merge/bulk",
				JSON.stringify([
					{
						key: "userInfo",
						url: `/user/login`,
						args: {
							login: userLogin,
						},
					},
					{
						key: "media",
						url: `media/special`,
						args: {
							user: userLogin,
							media: elementId,
							vector: vector || "both",
							album,
							type: "any",
							leftHash,
							rightHash,
							limit,
						},
						userLoginKey: "user",
					},
				]),
				{
					fromModal: true,
				}
			),
		noToast: true,
	};
}

export function mergeBulkMediaInfoAndComments({
	userLogin,
	elementId,
	type,
	signal,
	onlyContentInfo = false,
}) {
	return {
		types: [
			GET_MEDIA_INFO_AND_COMMENTS_BULK,
			GET_MEDIA_INFO_AND_COMMENTS_BULK_SUCCESS,
			GET_MEDIA_INFO_AND_COMMENTS_BULK_FAIL,
		],
		promise: (client) => {
			const dataToSend = onlyContentInfo
				? [
						{
							key: "contentInfo",
							url: `${type}`,
							args: {
								user: userLogin,
								id: elementId,
							},
							userLoginKey: "user",
						},
				  ]
				: [
						{
							key: "comment",
							url: `comment/file`,
							args: {
								user: userLogin,
								id: elementId,
								type,
								page: 1,
								limit: 10,
							},
							userLoginKey: "user",
						},
						{
							key: "contentInfo",
							url: `${type}`,
							args: {
								user: userLogin,
								id: elementId,
							},
							userLoginKey: "user",
						},
				  ];

			return client.post("/merge/bulk", JSON.stringify(dataToSend), {
				signal,
				fromModal: true,
			});
		},
		noToast: true,
		onlyContentInfo,
	};
}

export function setMainMediaInfo(payload) {
	return {
		type: SET_MAIN_MEDIA_INFO,
		payload,
	};
}
