import { combineReducers } from "@reduxjs/toolkit";
import { reducer as reduxAsyncConnect } from "redux-connect";
import reduceReducers from "reduce-reducers";
import cookie from "js-cookie";
import config from "configuration";
import auth from "./modules/auth";
import global from "./modules/global";
import messages from "./modules/messages";
import user from "./modules/user";
import userpanel from "./modules/userpanel";
import modal from "./modules/modal";
import mainPages from "./modules/mainPages";
import initialState from "./defaults";

export const SET_INITIAL_STATE = "root/SET_INITIAL_STATE";

function crossSliceReducer(state, action) {
	switch (action.type) {
		case SET_INITIAL_STATE: {
			cookie.remove(config.auth.cookieName, { path: "/" });
			const languageFromCookie = cookie.get(config.language.cookieName, {
				path: "/",
			});

			const finalLanguage = languageFromCookie
				? languageFromCookie.slice(0, 2)
				: "pl";
			return {
				...state,
				auth: {
					...initialState.auth,
				},
				global: {
					...initialState.global,
					language: finalLanguage ?? initialState.global.language,
					statistics: state.global.statistics,
					statisticsMain: state.global.statisticsMain,
					darkMode: {
						...initialState.global.darkMode,
						darkMode: state.global.darkMode.darkMode,
						isChangedByUser: state.global.darkMode.isChangedByUser,
					},
				},
				userpanel: {
					...initialState.userpanel,
				},
				messages: {
					...initialState.messages,
				},
				mainPages: {
					...initialState.mainPages,
					landing: {
						...initialState.mainPages.landing,
						data: {
							...initialState.mainPages.landing.data,
							images: state.mainPages.landing.data.images,
							locationsSeo:
								state.mainPages.landing.data.locationsSeo,
							preferencesSeo:
								state.mainPages.landing.data.preferencesSeo,
							randomVideoIndex:
								state.mainPages.landing.data.randomVideoIndex,
						},
					},
				},
				user: {
					...initialState.user,
				},
			};
		}
		default:
			return state;
	}
}

export function setInitialState() {
	return {
		type: SET_INITIAL_STATE,
	};
}

const staticReducers = {
	reduxAsyncConnect,
	auth,
	global,
	userpanel,
	messages,
	user,
	modal,
	mainPages,
};

export const injectReducers = (store, reducers) => {
	// Inicjalizacja asyncReducers jeśli nie istnieją
	if (!store.asyncReducers) store.asyncReducers = {};

	// Obsługa pojedynczego reducera (zachowanie kompatybilności wstecznej)
	if ("key" in reducers && "reducer" in reducers) {
		store.asyncReducers[reducers.key] = reducers.reducer;
	}
	// Obsługa wielu reducerów jako obiekt { [key]: reducer }
	else if (typeof reducers === "object") {
		store.asyncReducers = {
			...store.asyncReducers,
			...reducers,
		};
	}

	// Aktualizacja store z nowymi reducerami
	store.replaceReducer(
		reduceReducers(
			combineReducers({
				...staticReducers,
				...store.asyncReducers,
			}),
			crossSliceReducer
		)
	);
};

// Zachowujemy starą funkcję dla kompatybilności wstecznej
export const injectReducer = (store, { key, reducer }) => {
	injectReducers(store, { key, reducer });
};

const createRootReducer = (asyncReducers = {}) =>
	reduceReducers(
		combineReducers({
			...staticReducers,
			...asyncReducers,
		}),
		crossSliceReducer
	);

export default createRootReducer;
