import { configureStore } from "@reduxjs/toolkit";
import api from "helpers/ApiClient";
import deepmerge from "deepmerge";
import { window } from "ssr-window";
import syncReduxWithLocalstorage from "../libs/sync-redux-with-localstorage";
import createClientMiddleware from "./middleware/clientMiddleware";
import createRootReducer from "./reducer";

/**
 * Singleton instance of vendor with redux store object for global reference to the redux store getState or dispatch.
 */
const vendor = {
	store: null,
	history: null,
};

/**
 * Helper function that always returns a valid JS object that was readed from the reduxLocalStorage.
 * @returns
 */
function getReduxLocalState() {
	if (typeof window === "undefined" || !window.localStorage) {
		return null;
	}

	const encodedState = window.localStorage.getItem("reduxLocalStorage");

	if (encodedState == null) {
		return null;
	}

	return JSON.parse(encodedState);
}

/**
 * Creating redux store, with history that is required, and optional api client.
 * @param {*} state
 * @param {*} config
 * @returns
 */
function createStoreFromState(state, config = {}) {
	if (!config.history) {
		throw new Error(
			`store/vendor wymaga historii przekazanej w config przy createStoreFromState(..., [config])`
		);
	}

	const middleware = [
		createClientMiddleware(config.client || api),
		syncReduxWithLocalstorage({
			global: {
				user: true,
			},
		}),
	];

	const reduxLocalStorageState = getReduxLocalState();
	const userId = reduxLocalStorageState?.global?.user?.id;

	const finalState =
		reduxLocalStorageState && userId
			? deepmerge(state, reduxLocalStorageState)
			: state;

	vendor.history = config.history;

	const store = configureStore({
		reducer: createRootReducer(),
		preloadedState: finalState,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false,
			}).concat(middleware),
		devTools:
			config?.MODE?.__DEVELOPMENT__ ??
			process.env.NODE_ENV === "development",
	});

	if (!store.asyncReducers) {
		store.asyncReducers = {};
	}

	return store;
}

/**
 * Creating redux store server side.
 * @param {*} state
 * @param {*} config
 * @returns
 */
function createServerStoreFromState(state, config = {}) {
	return createStoreFromState(state, config);
}

/**
 * Creating redux store client side.
 * @param {*} state
 * @param {*} config
 * @returns
 */
function createClientStoreFromState(state, config = {}) {
	vendor.store = createStoreFromState(state, config);
	return vendor.store;
}

export { vendor, createServerStoreFromState, createClientStoreFromState };
